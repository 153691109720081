/* eslint-disable no-undef */
/* eslint-disable import/no-cycle */
import api from '../axios.service'

export default {
  getMember(data) {
    return api.get(`/member/list?search=${data.search}&date=${data.date}&status=${data.status}&page=${data.page}&size=${data.size}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },
  getReportMember(data) {
    return api.get(`report/report_member?search=${data.search}&date=${data.date}&status=${data.type}&page=${data.page}&size=${data.size}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },
  getReportMemberTable(data) {
    return api.get(`report/report_member_buy?search=${data.search}&date=${data.date}&status=${data.type}&page=${data.page}&size=${data.size}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },
  showMember(id) {
    return api.get(`member/show/${id}`)
  },

  memberStore(data) {
    const getFormData = data

    const params = new FormData()
    params.append('email', getFormData.member_email)
    for (const key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key])
      }
    }
    const config = {
      header: {
        'Content-Type': 'multipart/form-data',
      },
    }

    return api.post('member/store', params, config)
  },

  memberQuickStore(data) {
    const getFormData = data

    const params = new FormData()
    params.append('email', getFormData.member_email)
    for (const key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key])
      }
    }

    // let nameFile = getFormData.changeFile.name;

    // params.append('file', pattern.changeFile)
    // params.append('pattern_id', pattern.pattern_id)
    // params.append('pattern_name', nameFile)
    // params.append('pattern_detail', '')

    const config = {
      header: {
        'Content-Type': 'multipart/form-data',
      },
    }

    return api.post('member/quick_store', params, config)
  },

  memberUpdate(data) {
    const getFormData = data

    const params = new FormData()
    params.append('_method', 'PUT')
    params.append('email', getFormData.member_email)
    for (const key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key])
      }
    }
    const config = {
      header: {
        'Content-Type': 'multipart/form-data',
      },
    }

    return api.post(`member/update/${getFormData.member_id}`, params, config)
  },

  memberUpdateStatus(id) {
    // let getFormData = data

    const params = new FormData()
    params.append('_method', 'PUT')

    // for (var key in getFormData) {
    //   if (getFormData.hasOwnProperty(key)) {
    //     params.append(key, getFormData[key]);
    //   }
    // }
    // let config = {
    //   header: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }
    return api.put(`member/update_status_member/${id}`, params, {})
  },

  memberDestroy(data) {
    const getFormData = data

    const params = new FormData()
    for (const key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key])
      }
    }

    // let config = {
    //   header: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }
    return api.delete(`member/delete/${data.id}`, params, {})
  },

  setPrice(data) {
    const getFormData = data

    const params = new FormData()

    // params.append('_method','PUT')
    for (const key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key])
      }
    }
    const config = {
      header: {
        'Content-Type': 'multipart/form-data',
      },
    }

    return api.post('member/set_price', params, {})
  },
  memberDeleteLine(id) {
    //
    const params = new FormData()

    // for (var key in getFormData) {
    //   if (getFormData.hasOwnProperty(key)) {
    //     params.append(key, getFormData[key]);
    //   }
    // }
    // let config = {
    //   header: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }
    return api.delete(`member/member_delete_line/${id}`, params, {})
  },

  memberSetPrice(data) {
    const getFormData = data

    const params = new FormData()

    // params.append('_method','PUT')
    for (let i = 0; i < getFormData.product_type_id.length; i++) {
      params.append('product_type_id[]', getFormData.product_type_id[i])
      params.append('price[]', getFormData.price[i])
    }
    for (const key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        if (key != 'product_type_id' && key != 'price') {
          params.append(key, getFormData[key])
        }
      }
    }

    return api.post('member/member_set_price', params)
  },
  getMemberOrder(data) {
    return api.get(`/member/report_order_user/${data.member_id}?date=${data.date}`)
  },

}
