/* eslint-disable no-undef */
/* eslint-disable import/no-cycle */
import api from '../axios.service'

export default {
  getReportPackageCredit(data) {

    return api.get(`report_package_credit/get_report_package_credit?search=${data.search}&date=${data.date}&status=${data.status}&page=${data.page}&size=${data.size}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },

  getPackageCredit(data) {

    console.log(232);
    return api.get(`package_credit/getPackageCredit?search=${data.search}&date=${data.date}&status=${data.status}&page=${data.page}&size=${data.size}`)
  },

  showPoint(data){
    return api.get(`point/show?search=${data.search}&date=${data.date}&status=${data.OrderStatus}`)
  },

  cancelProductAmt(data) {
    let getFormData = data;
    let params = new FormData();
    params.append('_method', 'PUT');
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }
    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return api.post(`package_credit/updateCreditProductCreditCanceled/${data.id}`, params, config)
    // <!--                      {{url('admin/updateCreditProductAmtCanceled').'/'.$memberPackage->mem_package_id}}-->
  },
  confirmProductAmt(id) {
    // let getFormData = data;
    let params = new FormData();
    params.append('_method', 'PUT');
    // for (var key in getFormData) {
    //   if (getFormData.hasOwnProperty(key)) {
    //     params.append(key, getFormData[key]);
    //   }
    // }
    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return api.post(`package_credit/updateCreditProductCreditConfirm/${id}`, params, config)
    // <!--                      {{url('admin/updateCreditProductAmtCanceled').'/'.$memberPackage->mem_package_id}}-->
  },

  getPackageDetail(id) {

    return api.get(`package_credit/get_package_credit_detail/${id}`)
  },

  packageUpdateStatus(id){
    // let getFormData = data

    let params = new FormData();
    params.append('_method', 'PUT');
    // for (var key in getFormData) {
    //   if (getFormData.hasOwnProperty(key)) {
    //     params.append(key, getFormData[key]);
    //   }
    // }
    // let config = {
    //   header: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }
    return api.post(`package_credit/package_credit_updateStatus/${id}`, params, {})
  },

  store(data) {
    let getFormData = data

    let params = new FormData();
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }
    // let config = {
    //   header: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }
    return api.post(`package_credit/store`, params, {})
  },
  addPackage(data,details) {
    let getFormData = data

    let params = new FormData();
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key) && getFormData[key]) {
        params.append(key, getFormData[key]);
      }
    }
    params.append('data',JSON.stringify(details))
    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return api.post(`package_credit/add_package`, params, config)
  },

  update(data) {
    let getFormData = data

    let params = new FormData();
    params.append('_method', 'PUT');
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        if (getFormData[key]) {
          params.append(key, getFormData[key]);
        }else{
          params.append(key, '');
        }
      }
    }
    // let config = {
    //   header: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }
    return api.post(`package_credit/update/${data.id}`, params, {})
  },

  destroy(data) {
    let getFormData = data

    let params = new FormData();
    // // params.append('_method', 'DELETE');
    // for (var key in getFormData) {
    //   if (getFormData.hasOwnProperty(key)) {
    //     params.append(key, getFormData[key]);
    //   }
    // }
    // let config = {
    //   header: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }

    return api.delete(`package_credit/delete/${data.id}`, params, {})
  },

  getManagePackage(data){let getFormData = data

    let params = new FormData();
    // params.append('_method', 'DELETE');
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }
    // let config = {
    //   header: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }

    return api.get(`package_credit/get_package_credit`, params, {})

  },

  approvePay(data) {

    console.log(data,666)
    let getFormData = data;
    let params = new FormData();
    params.append('_method', 'PUT');
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key) && getFormData[key]) {
        params.append(key, getFormData[key]);
      }
    }
    return api.post(`package_credit/updateConfirmPay/${data.id}`,params,{})
  },
  cancelPay(data) {
    let getFormData = data;
    let params = new FormData();
    params.append('_method', 'PUT');
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key) && getFormData[key]) {
        params.append(key, getFormData[key]);
      }
    }
    return api.post(`package_credit/updateConfirmPay/${data.id}?confirm_pay=C`,params,{})

  },
  deletePay(id){

    let params = new FormData();

    return api.delete(`report_package_credit/member_package_delete/${id}`,params,{})
  }

}
