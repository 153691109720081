/* eslint-disable no-undef */
/* eslint-disable import/no-cycle */
import api from '../axios.service'

export default {
  get()
  {
    return api.get(`payment/list`)
  },
  showData(data) {

    return api.get(`payment/show/1`)
  },

  getOrderPayment() {

    return api.get('payment/get_order_payment')
  },

  getOrderPaymentDetail(id) {

    return api.get(`payment/get_order_payment/${id}`)
  },


  update(data) {
    let getFormData = data

    let params = new FormData();
    params.append('_method', 'PUT');
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }

    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return api.post(`payment/update/1`, params, {})
  },

  showPayment(data) {

    return api.get(`/order?search=${data.search}&date=${data.date}&status=${data.OrderStatus}`)
  },


  paymentUpdate(data) {
    let getFormData = data

    let params = new FormData();
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }
    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return api.put(`/orderUpdate`, params, {})
  },
}
